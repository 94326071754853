@import '../_palette.scss';

.drawer {
    position: fixed;
    width: 100%;
    max-width: 400px;
    height: calc(100vh - 55px);
    background-color: $primary;
    top: 55px;
    left: 0;
    z-index: 10;
    .footer {
        display: grid;
        justify-items: center;
        grid-template-rows: auto auto;
        row-gap: 20px;
        position: fixed;
        bottom: 60px;
        left: 100px;
        b {
            color: $white;
            font-size: $headerSize;
        }
    }
    .drawer-action {
        position: fixed;
        bottom: 20px;
        left: 20px;
        padding: 5px;
        color: $white;
        background-color: transparent;
        border-width: 2px;
        border-color: $white;
        border-style: solid;
    }

    .drawer-secondary-action {
        position: absolute;
        bottom: 19px;
        left: 90px;
        height: 32px;
        background: none;
        border: none;

        img {
            height: 100%;
        }
    }
}

@media (max-width: 350px) {
    .footer {
        img {
            display: none;
        }
        a,
        b {
            font-size: 24px !important;
        }
    }
}
@media (max-height: 800px) {
    .footer {
        bottom: 0px !important;
        left: 150px !important;
        img {
            display: none;
        }
        a,
        b {
            font-size: 24px !important;
        }
    }
    .drawerHeadings {
        font-size: 24px !important;
    }
}

@media (max-height: 700px) {
    .drawerHeadings {
        font-size: 18px !important;
    }
}

@media (max-height: 400px) {
    .drawerHeadings {
        font-size: 14px !important;
    }
}

.invisible-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
}

.drawerHeadingList {
    margin-left: 50px;
    margin-top: 55px;
    .drawerHeadings {
        color: $white;
        font-size: $headerSize;
        margin-bottom: 6px;
    }
    max-height: calc(100vh - 310px);
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.footer {
    display: grid;
    justify-items: center;
    grid-template-rows: auto auto;
    a,
    b {
        color: $white;
        font-size: $headerSize;
    }
}
