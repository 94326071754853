@import "../_palette.scss";

.pizzaHeader {
    width: 100%;
    margin: 0 auto;
    height: 30vw;

    overflow: hidden;
    position: relative;
    // margin-bottom: -60px;
    display: flex;

    .full {
        position: absolute;
        bottom: 0;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    @media (min-width: 550px) {
        height: 160px;
        width: 600px;
        // margin-bottom: -10vw;
    }

    @media (min-width: 1600px) {
        margin-bottom: -160px;
    }
}
