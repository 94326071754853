@import "../_palette.scss";

.button-container {
    display: inline-block;
    // margin-right: 16px;
    // margin-top: 30px;
    button {
        height: 40px;
        width: 100px;
        background-color: $white;
        border: none;
        border-radius: $borderRadius;
        @include boxShadow();
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        justify-items: center;
        img {
            color: $primary;
        }
        &.active {
            color: $white;
            background-color: $primary;
        }
    }
}
