@import '../_palette.scss';

.radio-group {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 16px;

    width: 230px;
    max-width: calc(100% - 40px);
    // margin: 0 20px;

    .radio-button {
        aspect-ratio: 1/1;

        font-size: 26px;
        border-radius: $borderRadius;
        background-color: $white;
        border-width: 0px;
        text-align: center;
        @include boxShadow();
        color: $inActive;

        &.active {
            background-color: $primary;
            color: $white;
        }
    }
}
