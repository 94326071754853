@import '../_palette.scss';

.product-naviation {
    position: fixed;
    width: 100%;
    max-width: $contentWidth;

    left: calc((100% - #{$contentWidth}) / 2);
    // margin: 0 auto;
    top: $header_height;

    // Mdeia query over contentWidth
    @media (max-width: $contentWidth) {
        left: 0;
        width: 100%;
    }

    select {
        appearance: none;
        width: 100%;
        background-color: $white;
        border-width: 0px;
        border-radius: 0 0 9px 9px;

        @include boxShadow;

        height: 65px;
        text-align-last: center;

        font-family: 'Futura-Bold';
        font-size: $headerSize;
        color: $primary;
        text-decoration: none;

        &:focus {
            outline: none;
        }
    }

    .icon {
        position: absolute;
        right: 20px;
        top: 50%;
    }
}
