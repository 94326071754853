@import '../_palette.scss';

.jumbotron {
    height: 100%;
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 100%;
    bottom: 0;

    .jumbo-container {
        height: 80%;
        @include mainContainer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1;
        margin-top: 89px;
        // margin-bottom: 50px;

        text-transform: uppercase;
        h1,
        h2 {
            margin: 0;
            text-shadow: 0px 3px 6px rgba(255, 255, 255, 0.17);
        }

        h1 {
            font-size: $headerSize;
            color: white;
        }
        h2 {
            // margin-top: -5px;
            // margin-left: 10px;
            font-size: $textSize;
            color: white;
        }

        a {
            width: 210px;
            margin: 0 auto;
            display: block;
            button {
                width: 100%;
                padding: 15px;
                border-radius: 9px;
                border: none;
                color: white;
                background-color: $primary;
                font-size: $headerSize;

                &:focus {
                    outline: none;
                }

                // Aukapæling?
                font-size: $textSize;
                text-transform: uppercase;
                border-radius: 9;

                cursor: pointer;
                &:hover {
                    background-color: lighten($primary, 5);
                }
            }
        }
    }

    .fade-overlay {
        position: absolute;
        opacity: 0.7;
        top: 0;
        height: 100%;
        width: 100%;

        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 212, 255, 0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 212, 255, 0) 100%);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 212, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#00d4ff",GradientType=1);
    }
}
