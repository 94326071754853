@import '../_palette.scss';

.textarea-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    b {
        width: 100%;
        margin-bottom: 9px;
    }
    textarea {
        height: $inputHeight;
        border-width: 0px;
        border-radius: $borderRadius;
        padding-left: 10px;
        background-color: #f2f2f2;
        resize: none;
        height: 120px;

        @include boxShadow();
    }
}
