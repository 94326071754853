@import '../_palette.scss';

.product-card {
    cursor: pointer;
    position: relative;
    display: grid;
    grid-template-areas:
        'name img'
        'toppings img'
        'message img'
        'price img'
        'orderBtn img';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    text-decoration: none;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-top: 24px;
    height: 230px;
    z-index: 0;
    .cardBG {
        position: absolute;
        background-color: $white;
        border-radius: $borderRadius;
        height: 100%;
        width: 80%;
        max-width: 380px;
        z-index: -1;
        @include boxShadow;
    }

    .pizzaName {
        grid-area: name;
        margin-top: 0px;
        font-size: 18px;
    }

    .toppings {
        grid-area: toppings;
        margin: 0px;
    }

    .variant-prices {
        grid-area: price;
        align-self: end;

        .pizzaPrice {
            display: grid;
            grid-template-columns: 1fr 6fr;
            grid-gap: 10px;
            margin-top: 3px;

            .var-label {
                color: $darker;
                justify-self: end;
            }
        }
    }

    .orderBtn {
        position: relative;
        grid-area: orderBtn;
        color: $white;
        background-color: $primary;
        width: 120px;
        height: 30px;
        border-width: 0px;
        border-radius: $borderRadius;
        align-self: end;
        align-items: center;

        .icon {
            transition: all 0.1s ease-in-out;
            position: absolute;
            left: 10px;
            top: 7px;
        }

        &:hover {
            width: 130px;
            height: 35px;
            margin-left: -5px;
            margin-bottom: -3px;

            .icon {
                position: absolute;
                left: 14px;
                top: 10px;
            }
        }
        transition: all 0.1s ease-in-out;
    }

    .img-container {
        grid-area: img;
        align-self: top;
        justify-self: center;
        height: 100%;

        .pizzaImg {
            object-fit: contain;
            height: 100%;
            width: 100%;

            max-width: 100%;
            max-height: 180px;

            padding: 5px;
        }
    }

    .message {
        color: $primary;
        grid-area: message;
    }

    &.disabled {
        opacity: 0.5;
    }
}
