@import '../_palette.scss';

.stickySection {
    // display: grid;
    position: sticky;
    top: 34px;
    width: calc(100% - (2 * #{$contentPadding}));

    max-width: $contentWidth;
    margin: 20px auto;

    .title {
        font-size: $headerSize;
    }
    .subtitle {
        margin-top: -10px;
        font-size: $headerSize;
    }
}
