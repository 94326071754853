@import "../_palette.scss";

.select-container {
    display: grid;
    grid-template-rows: auto auto;
    margin-bottom: 16px;
    width: calc(100% - 80px);

    b {
        margin-bottom: 9px;
        margin-top: 9px;
    }
    select {
        height: $inputHeight;
        border-width: 0px;
        border-radius: $borderRadius;
        padding-left: 10px;
        @include boxShadow();
    }
}
