@import '../_palette.scss';

.cart-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100000;

    .cart-modal {
        $topPadding: 90px;
        width: calc(100% - #{$contentPadding} - 30px);
        height: calc(100vh - #{$topPadding} - 70px);

        max-height: 900px;
        min-height: 300px;
        max-width: 335px;

        margin: 0 auto;
        padding: 15px;
        position: relative;
        z-index: 5;
        background-color: $bright;
        top: $topPadding/2;
        overflow: hidden;
        border-radius: $borderRadius;
    }

    .checkoutBtn {
        // position: relative;
        // bottom: 0px;
        // z-index: 10;
        // width: calc(100% - #{$contentPadding});
        // max-width: 365px;
        // margin: 0 auto;

        button {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            background-color: $primary;
            border: none;
            padding: 10px;
            border-radius: $borderRadius;
            display: grid;
            grid-template-areas: 'nothing panta price';
            grid-template-columns: 1fr 3fr 1fr;
            align-items: center;
            // height: 100%;
            .cta-subtitle {
                grid-area: price;
                color: $white;
            }
            .panta {
                grid-area: panta;
                font-family: 'Futura-Bold';
                color: $white;
                font-size: 28pt;
                margin: 10px;
            }
        }
    }

    .uppsellBtn {
        // position: relative;
        // top: 0px;
        // z-index: 10;
        // width: calc(100% - #{$contentPadding});
        // max-width: 365px;
        // margin: 0 auto;

        position: absolute;
        bottom: 100px;
        width: calc(100% - 20px);

        button {
            width: 100%;
            background-color: white;
            border: none;
            padding: 10px;
            border-radius: $borderRadius;
            position: relative;

            img {
                position: absolute;
                bottom: -30px;
                right: -7%;
                width: 30%;
            }
        }
    }

    .cart-background {
        top: 0;
        z-index: 2;
        opacity: 0.41;
        height: 100%;
        width: 100%;
        position: fixed;
        background-color: black;
    }

    .backButton {
        background-color: $primary;
        border-radius: 10px;
        border: 0;
        padding: 10px;
        color: white;
    }

    .imgContainer {
        display: flex;
        justify-content: center;
        padding: 10px;
        background: rgba(black, 0.05);
        border-radius: 10px;
        img {
            height: 100px;
        }
    }

    .cart-upsell-button {
        background-color: $primary;
        width: 100%;
        border-radius: 10px;
        border: 0;
        padding: 10px;
        color: white;
    }
}
