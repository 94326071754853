@import "../_palette.scss";

.hot-bar {
    background-color: $primary;
    position: fixed;
    bottom: 0;
    border-radius: 9px 9px 0 0;
    height: 59px;

    width: 100%;
    max-width: $mobileWidth;

    @media (min-width: $mobileWidth) {
        left: calc(50vw - #{$mobileWidth} / 2);
    }
    
    cursor: pointer;

    h2,
    p {
        color: white;
    }

    h2 {
        position: absolute;
        width: 100%;
        text-align: center;
        font-size: 30px;
        top: 0;
        left: 0;
        margin: 13px 0;
    }

    p {
        float: right;
        margin: 22px 20px;
    }
}
