@import '../_palette.scss';

.dropDown {
    cursor: pointer;
    margin-top: 18px;
    font-family: 'Futura-Bold';
    text-decoration: none;

    display: inline-block;
    position: relative;
    background-color: white;

    padding: 10px;
    border-radius: $borderRadius;

    @include boxShadow();
    margin-bottom: 10px;

    z-index: 3;

    // min-height: 60px;

    &:nth-child(2) {
        margin-left: -10px;
        z-index: 2 !important;
        padding-left: 20px;
    }

    p {
        font-family: 'Futura-Bold';
        padding: 5px;
        margin: 0;
        color: $black;
    }

    select {
        color: $black;
        background-color: transparent;
        border: none;
        font-size: 18px;
        option {
            color: $black;
        }
        width: 100%;
    }

    // mobile only
    margin-left: 20px;
    @media (max-width: $mobileWidth) {
        width: calc(100% - 20px);
        &.divided {
            width: calc(50% - 20px) !important;
        }
        margin-left: 0;
    }

    &.active {
        background-color: $primary;
        p,
        select {
            color: white;
        }
    }

    &.divided {
        select {
            max-width: 140px;
        }

        width: auto;
    }
}
