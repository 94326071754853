@import '../_palette.scss';

.cart-items {
    &.fixed {
        overflow: scroll;
    }
    height: 100%;
    margin-bottom: 30px;

    .cart-item {
        background-color: $white;
        border-radius: $borderRadius;
        padding: 10px;
        margin-bottom: 15px;
        display: grid;
        grid-template-areas:
            'name remove'
            'description description'
            'change price';
        .item-name {
            grid-area: name;
            margin: 0px;
        }
        .remove {
            grid-area: remove;
            justify-self: right;
        }
        .item-price {
            grid-area: price;
            font-family: 'Futura-Light';
            justify-self: right;
        }
        .item-description {
            grid-area: description;
        }
    }

    .padding-space {
        height: 180px;
    }
}
