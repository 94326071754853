@import '../_palette.scss';

.check-group {
    .toppingsInGroup {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .check-box {
            margin-right: 10px;
            margin-top: 10px;
            display: flex;
            @include boxShadow();
            button {
                background-color: $white;
                border: none;
                padding: 8px;

                font-family: 'Futura-Light';

                &.active {
                    padding: 6px;
                    background-color: $primary;
                    color: white;
                    border: 2px solid $primary;
                }

                &.active + button {
                    display: inline !important;
                }

                &.double {
                    display: none;
                    border-left: none;
                }
            }
            .check-mark {
                // padding: 8px;
                width: 30px;

                display: flex;
                justify-content: center;
                align-items: center;

                .check-mark-border {
                    border: 1px solid $primary;
                    height: 12px;
                    width: 12px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        display: none;
                        position: absolute;
                    }
                }

                // background-color: white;
                background-color: white;

                &.active {
                    background-color: $primary;

                    .check-mark-border {
                        border: 1px solid white;
                        p {
                            display: block;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
