@import '../_palette.scss';

.header {
    position: fixed;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 10px 15px;

    background-color: $primary;
    height: $header_height;
    @include boxShadow;

    .action {
        cursor: pointer;
        padding: 15px;
    }

    .logo {
        margin-top: 4px;
        height: 39px;
    }

    z-index: 10000 !important;

    &-notification {
        font-size: $textSize;
        @include boxShadow;
        height: 31px;
        top: $header_height;
        position: absolute;
        width: 100%;
        background-color: white;
        z-index: 1;
        display: flex;
        justify-content: center;

        p {
            margin: 5px;
        }

        img {
            height: 20px;
            margin: 5px 0px;
        }
    }

    &.not {
        margin-bottom: 30px;
    }

    .order-button {
        color: white;
        background-color: transparent;
        border: 2px solid white;
        border-radius: 9px;
        padding: 4px 12px;
        margin: 10px;
    }
}
