@import '../_palette.scss';

.location-card {
    background-color: $white;
    border-radius: $borderRadius;
    @include boxShadow();
    padding: 15px;
    // min-height: 300px;
    position: relative;
    // margin-bottom: 40px;
    h1 {
        margin-top: 5px;
        font-family: 'Futura-Bold';
        font-size: $headerSize;
        color: $primary;
    }
    .opening-hours {
        p {
            margin: 1px;
        }
        margin-bottom: 80px;
    }

    .times {
        position: absolute;
        bottom: 15px;
        width: calc(100% - 30px);
        .time {
            display: grid;
            grid-template-areas: 'left right';
            margin-bottom: 10px;
            .left {
                font-family: 'Futura-Bold';
                font-size: $textSize;
                grid-area: left;
                margin: 0px;
            }
            .right {
                font-family: 'Futura-Bold';
                font-size: $textSize;
                justify-self: right;
                grid-area: right;
                margin: 0px;
            }
        }
    }
}
