@import "../_palette.scss";

.list {
    background-color: $white;
    padding: 25px;
    margin-bottom: 30px;
    border-radius: $borderRadius;
    img {
        height: 24px;
        margin-right: 14px;
    }
    @include boxShadow();
    .whatUWant {
        font-size: $headerSize;
        margin-top: 0px;
    }
}
