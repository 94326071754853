@import "../_palette.scss";

.notice {
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($primary, 0.85);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 300px;
        max-width: 50%;
    }

    h1 {
        font-size: 20px;
        text-transform: uppercase;
        color: white;
        margin-top: 30px;
    }
}
