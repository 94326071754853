@import '../_palette.scss';

form.form {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    @include boxShadow();
    margin-top: 28px;
    button {
        margin-top: 20px;
        width: 100%;
        @include bigButtonBasics();
        &.disabled {
            background-color: gray;
        }

        img {
            margin-right: 10px;
            margin-bottom: -3px;
        }
    }

    h1 {
        font-size: 32px;
        color: $primary;
    }

    .dual {
        display: flex;
        button {
            margin-top: 0;
            &:first-child {
                margin-right: 20px;
            }
        }
    }

    .loading {
        position: relative;
        pointer-events: none;
        color: transparent;
    }

    .loading:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
        border: 2px solid #fff;
        border-top-color: transparent;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
}
