@import "../_palette.scss";

.dropTitle {
    margin-top: 18px;
    font-family: "Futura-Bold";
    font-size: $headerSize;
    color: $black;
    text-decoration: none;
    
    select {
        width: 90%;
        background-color: transparent;
        border: none;
    }

    &.small {
        font-size: 16px;
    }
}
