@import '../_palette.scss';

.locationSection {
    width: calc(100% - 40px);
    // max-width: $sectionWidth;
    margin: 20px auto;

    .title {
        font-size: $headerSize;
    }
    .subtitle {
        margin-top: -10px;
        font-size: $headerSize;
    }

    &.wide {
        max-width: $contentWidth;
    }
    .locationSectionContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
        }
        grid-gap: 30px;
    }
}
