@import "../_palette.scss";

.tool-bar {
    @include mainContainer;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px;
    max-width: 450px;

    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;

    button {
        width: 100%;
        height: 55px;
        background-color: $white;
        color: $black;
        border-radius: $borderRadius;
        border-width: 0px;
        @include boxShadow;
    }
}
